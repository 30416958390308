@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;600;800;900&family=Poppins:wght@300;400;500;600;700&display=swap');
:root {
    --main-color: #FFFFFF;
    --color-dark: #1D2231;
    --text-grey: #8390A2;
}



* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}
h3, p , h2{
  margin: 0 !important;
}
body{
    background-color: #F8F9FF;
}
p{
    padding: 0;
    margin: 0;
}

*:focus {
  outline: none!important;
}
Link {
  text-decoration: none !important;
}

/* sidebar start  */
.sidebar {
    width: 260px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: var(--main-color);
    z-index: 100;
    transition: margin-left 300ms;
}

.sidebar-brand {
    height: 90px;
    padding: 1rem 0rem 1rem 2rem;
    text-align: center;
}
.sidebar-brand span {
    display: inline-block;
    padding-right: 1rem;
}

.sidebar-help{
    position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}
.dark-card{
  background: var(--color-dark);
  border-radius: 8px;
  color: white;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}
.dark-card h4 {
  font-size: 14px;
  padding: 15px 0px;
}
.help-box{
    display: flex;
    margin-bottom: -20px;
}
.help-icon {
    font-size: 32px;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: #017EFA;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.help-icon a{
    color: #017EFA;
    padding-top: 10px;
}

.sidebar-menu{
    margin-top: 60px;
}
.sidebar-menu li {
    width: 100%;
    margin-bottom: 30px;
    padding-left: 30px;
    text-decoration: none;
}

.sidebar-menu a {
    padding-left: 15px;
    display: block;
    color: #6B6E6F;
    font-size: 15px;
    text-decoration: none;
}

.sidebar-menu a:hover{
  color: #3f4041;
}

.pipe-add-input
.sidebar-menu a.active {
    background-color: #1E1E2D;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--main-color);
    border-radius: 8px 0px 0px 8px;
    font-size: 18px;
}

.sidebar-active{
    background-color: #1E1E2D;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #FFFFFF !important;
    border-radius: 8px 0px 0px 8px;
    font-size: 18px;
}

.sidebar-menu a span:first-child {
    /* font-size: 1.5rem; */
    padding-right: 15px;
}

/* sidebar end  */

/* button  */
.btn-white {
    background: #F8F9FF;
    border-radius: 8px;
    color: #1E1E2D;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 25px;
    cursor: pointer;
}

.btn-dark {
    background: #1E1E2D;
    border-radius: 50px;
    color: #F8F9FF;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none !important;
}
.btn-dark:hover{
    color: #F8F9FF;
    background: #29293d;
}

.btn-transparent {
    color: #1E1E2D !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 10px 20px !important;
}
.btn-transparent:hover{
    color: #29293d;
}
.stage-btn, .tag-btn{
    border: none;
    color: #1E1E2D !important;
    width: 154px;
    text-align: left;
}
.transparent{
    border: none;
    background: none;
}
.btn-cal{
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    color: #017EFA;;
}
.btn-cal:hover{
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    color: #0070e0;;
}
/* button end  */

.display-jb-alert{
    background: #76BBFF;
    text-align: center;
}
.nav-label{
    font-size: 20px !important;
    cursor: pointer;
}

#nav-toggle:checked  + .sidebar{
    width: 70px;
}

#nav-toggle:checked  + .sidebar .sidebar-brand,
#nav-toggle:checked  + .sidebar li {
    padding-left: 10px;
    text-align: center;
}


#nav-toggle:checked  + .sidebar .sidebar-brand h2 span:last-child,
#nav-toggle:checked  + .sidebar li a span:last-child{
    display: none;
}

#nav-toggle:checked  + .sidebar .sidebar-help .dark-card{
    display: none;
}

#nav-toggle:checked ~ .main-content{
    margin-left: 70px;
}

#nav-toggle:checked ~ .main-content header{
    width: calc(100% - 70px);
    left: 70px;
}

.main-content {
    transition: margin-left 300ms;
    margin-left: 260px;
}

/* header  */

header {
    background: #F8F9FF;
    display: flex;
    justify-content: space-between;
    padding: 5px 50px 5px 10px;
    border-bottom: 1px solid #DADADA;
    /* box-shadow: 4px 4px 10px rgba(0,0,0,0.2); */
    position: fixed;
    left: 260px;
    width: calc(100% - 260px);
    top: 0;
    z-index: 100;
    transition: width 300ms;
}

#nav-toggle {
    display: none;
}

.header-left, .header-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
}
.lf{
    flex: 1 1 auto;
  margin: 5px;
}
header h2 {
    color: #222;
}

header .nav-label span{
    font-size: 1.7rem;
    padding-right: 1rem;
}

.search-wrapper {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    height: 40px;
    width: 235px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    background: #ffffff;;
}

.search-wrapper span{
    display: inline-block;
    padding: 0px 10px;
    font-size: 1.5rem;
}

.search-wrapper input{
    height: 100%;
    padding: .5rem;
    border: none;
    outline: none;
    background: none;
}



main {
    margin-top: 85px;
    padding: 2rem 2rem;
    min-height: calc(100vh - 90px);
}
.nopad-main{
    padding: 2rem 0rem;
}
.nopad-main .main-title{

    padding: 0rem 2rem;
}

.main-title{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #1C1F37;
}
.main-sub-title{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    color: #1C1F37;
}
.icon-btn{
  font-size: 20px !important;
  padding: 5px 10px !important;
}
/* cards  */
.cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin-top: 1rem;
    padding-bottom: 40px;
}

.card-single {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF;
    padding: 30px;
    border-radius: 6px;
}

.card-icon{
    font-size: 45px;
}
.card-title h1{
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #1C1F37;
}
.card-title span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(28, 31, 55, 0.58);
}

.card-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.card-header-title h2{
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #1C1F37;
}

/* colors  */
.cl-blue{
    color: #017EFA;
}
.cl-green{
    color: #61C260;
}
.cl-yellow{
    color: #FFD817 ;
}
.applied-btn{
    background-color: #E2F1FF;
}
.interview-btn{
    background-color: #D4FFED;
}
.rejected-btn{
    background-color: #FFDDDD;
}
.hired-btn{
    background-color: #D3FED3;
}
.bg-neutral{
background: #F5F5F5;
border-radius: 10px;
}
.bg-soft{
  background: #F3F9FC;
}
.nav-link{
  color: #1C1F37 !important;
}
.nav-link.active{
  background-color: transparent!important;
  color: #1C1F37 !important;
  border-bottom: 5px solid #1C1F37 !important;
}
.tab-link a{
  padding: 12px 12px 12px 45px;
  border: 0 !important;
}
.tab-link a:hover{
  background: rgba(240, 244, 249, 0.8);
}
.tab-link .active{
  background: #1C1F37 !important;
  color: white !important;
  border: 0 !important;
}
/* end colors  */

hr {
    opacity: 0.5;
    border: 1px solid #DADADA;
    margin-bottom: 0px;
}

.active-label{
    height: 10px;
    width: 10px;
    background-color: #61C260;
    border-radius: 50%;
    display: inline-block;
}
.inactive-label{
    height: 10px;
    width: 10px;
    background-color: #FA3F3F;
    border-radius: 50%;
    display: inline-block;
}
.circle{
  background: #E2F1FF;
  color: #67D2FF;
    border-radius: 50%;
    padding: 5px 7px;
}
.files-tab li{
  width: 240px;
  height: 100%;
  background: white;
  box-shadow: 0px 5px 10px #F1F2FA;
  border-radius: 8px;
  
  margin-top: 10px;
}
.email-receipents{
  text-overflow: ellipsis
}
.file-names {
  margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 12px;
}

.add-container{
  width: 80%;
}
.add-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.al-center{
  display: flex;
  align-items: center;
}
.add-left{
  flex: 30%;
}
.upload-image{
  width: 123px;
  height: 123px;
  margin: 0 auto;
  border-radius: 100%;
  background: #F0F0F0;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-image span{
  
}
/* Recent projects  */
.recent-grid {
    margin-top: 3.5rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 70% auto;
}

button {
    background: var(--color-dark);
    border-radius: 100px;
    color: #fff;
    font-size: .8rem;
    padding: .5rem 1rem;
    border: 1px solid var(--color-dark);
}

table {
    border-collapse: collapse;
}

thead tr {
    border: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}
.email-loading-top{
  background-color: #1D2231;
  color: #fff;
}
.submenu-data{
  height: 200px;
  width: 170px;
  overflow-y: visible;
  overflow-x: scroll;
}

thead td {
    font-weight: 700;
}
td {
    padding: .5rem;
    font-size: .9rem;
    border-radius: 50%;
    color: #222;
    align-items: center;
}

td .status {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 1rem;
} 

/* tr td:last-child{
    display: flex;
    align-items: center;
} */

.status.purple{
    background: rebeccapurple;
}
.status.pink{
    background: deeppink;
}
.status.orange{
    background: orangered;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
}

/* todo  */
.todo {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #F1F2FA;
    border-radius: 8px;
    padding: 15px 23px;
    margin-top: 10px;
}
.card-body-card ul li{
    border-bottom: 1px solid #DADADA;
    padding-top: 10px;
}
.todo-tag{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 15px 0px;
}
.todo-tag-left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 70%;
}
.todo-tag-right{
    flex: 30%;
    font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 12px;
}
.tg-l{
    flex: 20%;
    text-align: center;
    font-size: 20px;
}
.tg-r{
    flex: 80%;
}
.tg-r span{

    font-size: 15px;
}
.tag-text{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #1C1F37;
}
.todo-done{
    text-decoration-line: line-through;

}
.todo-done .remove {
    text-decoration: none;
  }

  .unticked, .ticked{
    cursor: pointer !important;
    padding-right: 10px;
  }
  .todo-checkbox, .ticked {
    display: none;
  }

  .todo-checkbox:checked ~ .unticked {
      display: none !important;
  }

  .todo-checkbox:checked ~ .ticked {
    display: block !important;
    cursor: pointer;
}

/* graph  */
.graph-chart .line-chart{
    height: 232px;
    padding-bottom: 40px;
}

/* activities  */
.bg-whitee{
    background: #FFFFFF;
    box-shadow: 0px 5px 10px #F1F2FA;
    border-radius: 8px;
    padding: 15px 23px;
    margin-top: 10px;
}
.activity ul {
    padding-bottom: 20px;
}
.activity ul li{
    margin-bottom: 20px;
    padding-top: 10px;
    border-bottom: 1px solid #DADADA;
}
.activity-tag{
    display: inline-flex;
}
.activity-icon{
    padding-right: 20px;
}
.activity-text{
    font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 16px;
}
.activity-text span{
    font-weight: bold;

}

.settings{
  height: 100vh;
  background: #FFFFFF;
  /* position: fixed; */
  width: 100vw;
}
.main-mailbox{
  height: 100vh;
  background: #FFFFFF;
  position: fixed;
  width: 90vw;
  display: flex;
}
.sidemenu{
  width: 30vw;
}
.mail-list-container{
  width: 70vw;
}
.scroll_segment{
  height: 65vh;
  overflow-x: hidden !important;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}
.scroll_segment::-webkit-scrollbar{
  /* display: none; */
  /* background-color: #1C1F37; */
}

.page-link{
  color: var(--dark-color); 
}


.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #1D2231;
  border-color: #1D2231;
}

/* === Email === */
/* Mail Sidebar */
@media (max-width: 769px) {
     .mail-sidebar {
      position: relative;
    }
    .add-container{
      width: 100%;
    } 
  }
  
  @media (max-width: 767.98px) {
     .mail-sidebar {
      position: fixed;
      z-index: 99;
      /* background: #ffffff; */
      width: 45%;
      min-width: 300px;
      left: -100%;
      display: block;
      transition: 0.4s ease;
      -webkit-transition: 0.4s ease;
      -moz-transition: 0.4s ease;
    }
  }
  
   .mail-sidebar .menu-bar {
    width: 100%;
    float: right;
    height: 100%;
    min-height: 100%;
  }
  
  @media (max-width: 767.98px) {
     .mail-sidebar .menu-bar {
      min-height: 100vh;
      max-height: 100%;
      height: auto;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .mail-sidebar{
      padding: 0;
  }
   .mail-sidebar .menu-bar .menu-items {
    padding: 0;
    margin-bottom: 0;
    height: auto;
    list-style-type: none;
  }
  
   .mail-sidebar .menu-bar .menu-items li {
    padding: 15px 30px;
    transition: 0.4s;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    cursor: pointer;
  }
  
   .mail-sidebar .menu-bar .menu-items li:hover {
    background: rgba(240, 244, 249, 0.8);
  }
  
   .mail-sidebar .menu-bar .menu-items li a {
    color:  #6B6E6F;;
    font-size: 15px;
    text-decoration: none; 
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  
   .mail-sidebar .menu-bar .menu-items li a i {
   
  }
  
   .mail-sidebar .menu-bar .menu-items li.active {
    background: #1E1E2D;
    /* border-radius: 4px; */
  }
  
   .mail-sidebar .menu-bar .menu-items li.active a {
    color: #FFFFFF;
  }
  
   .mail-sidebar .menu-bar .menu-items li.compose:hover {
    background: transparent;
  }
  
   .mail-sidebar .menu-bar .online-status {
    margin-top: 1rem;
  }
  
   .mail-sidebar .menu-bar .online-status .chat {
    font-size: 0.75rem;
    color: #464dee;
    margin-bottom: 0;
    font-weight: 600;
  }
  
   .mail-sidebar .menu-bar .online-status .status {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: flex-start;
    transform: translateX(-43px) translateY(2px);
  }
  
   .mail-sidebar .menu-bar .online-status .status:after {
    font-size: 12px;
    color: #2e383e;
    margin: -5px 0 0 18px;
  }
  
   .mail-sidebar .menu-bar .online-status .status.offline {
    background: #ef5958;
  }
  
   .mail-sidebar .menu-bar .online-status .status.offline:after {
    content: "Offline";
  }
  
   .mail-sidebar .menu-bar .online-status .status.online {
    background: #0ddbb9;
  }
  
   .mail-sidebar .menu-bar .online-status .status.online:after {
    content: "Online";
  }
  
   .mail-sidebar .menu-bar .profile-list {
    padding: 10px 0;
  }
  
   .mail-sidebar .menu-bar .profile-list-item {
    border-bottom: 1px solid #e9e9e9;
    padding: 6px 0;
    display: block;
  }
  
   .mail-sidebar .menu-bar .profile-list-item:last-child {
    border-bottom: 0;
  }
  
   .mail-sidebar .menu-bar .profile-list-item a {
    text-decoration: none;
  }
  
   .mail-sidebar .menu-bar .profile-list-item a .pro-pic {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0;
    width: 20%;
    max-width: 40px;
  }
  
   .mail-sidebar .menu-bar .profile-list-item a .pro-pic img {
    max-width: 100%;
    width: 100%;
    border-radius: 100%;
  }
  
   .mail-sidebar .menu-bar .profile-list-item a .user {
    width: 100%;
    padding: 5px 10px 0 15px;
  }
  
   .mail-sidebar .menu-bar .profile-list-item a .user .u-name {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1;
    color: #000000;
  }
  
   .mail-sidebar .menu-bar .profile-list-item a .user .u-designation {
    font-size: calc(0.75rem - 0.1rem);
    margin-bottom: 0;
  }
  
   .sidebar.open {
    left: 0;
  }
  .loginpage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  /* Mail List Container */
   .mail-list-container {
    border-left: 1px solid #e9e9e9;
    /* height: 100vh; */
    padding-left: 0;
    padding-right: 0;
  }
  
   .mail-list-container a {
    text-decoration: none;
  }
  
   .mail-list {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-direction: row;
    padding: 20px 5px;
    width: 100%;
  }
  .mail-list:hover{
    background-color: #FBFBFB;
    cursor: pointer;
  }
  .mail-list-active{
    background-color: #E2F1FF;
    cursor: pointer;
  }
  
   .mail-list-container .mail-list:last-child {
    border-bottom: none;
  }
  
  .mail-list-container .mail-list .form-check {
    margin-top: 12px;
    width: 11%;
    min-width: 20px;
  }
  
  .mail-list .content {
    width: 70%;
    padding-left: 0;
    padding-right: 0;
    height: 60px;
    overflow: hidden;
  }
  
   .sender-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .flex-1{
    width: 25%;
  }

  .flex-2{
    width: 65%;
    height: 90vh;
    overflow-x: hidden !important;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    padding-bottom: 100px;
  }
.scrollstuff{
  height: 90vh;
  overflow-x: hidden !important;
  overflow-y: auto;  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
  padding-bottom: 100px;
}
   .message_text, .message_text span {
    margin: 0;
    max-width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 10px !important;
    line-height: 12px;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    background-color: transparent !important;
  }

  .sender-img{
    position: relative;
    padding: 0px 5px;
    width: 20%;
  }
  .mail-view{
    width: 900px;
  }
  .msg-box-width{
    width: 800px;
  }
  .mail-subject{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
  
   .mail-list-container .mail-list .details {
    width: 20%;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }
  
   .mail-list-container .mail-list .details .date {
    text-align: right;
    margin: auto 15px auto 0;
    white-space: nowrap;
    font-size: 10px;
  }
  
  .date{
    white-space: nowrap;
    font-size: 12px;
  }
   .mail-list-container .mail-list .details i {
    margin: auto 0;
    color: #ddd;
  }
  
   .mail-list-container .mail-list .details i.favorite {
    color: #fcd539;
  }
  
   .mail-list-container .mail-list.new_mail {
    background: #e6e9ed;
  }
  
   .mail-list-container .mail-list.new_mail .details .date {
    color: #000000;
  }
  .active-mail{
    position: absolute;
    height: 10px;
    width: 10px;
    background-color:#017EFA;;
    border-radius: 50%;
  }
  .opened-mail{
    color: #6B6E6F;
  }
  /* Message Content */
.mailbox_container_message{
  width: 85%;
}
   .message-body .sender-details {
    padding: 20px 15px 0;
    border-bottom: 1px solid #e9e9e9;
    display: -webkit-flex;
    display: flex;
  }
  
   .message-body .sender-details .details {
    padding-bottom: 0;
  }
  
   .message-body .sender-details .details .msg-sender {
    font-weight: 600;
    font-size: 15px;
  }

  .msg-sender{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
  }
  
   .message-body .sender-details .details .sender-email {
    margin-bottom: 20px;
    font-weight: 400;
  }
  
   .message-body .sender-details .details .sender-email i {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 1px 0 7px;
  }
  
   .message-body .message-content {
    padding: 15px 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    background-color: white;
    border-radius: 10px;
  }
  
   .message-body .attachments-sections ul {
    list-style: none;
    padding: 30px 15px 20px;
  }
  
   .message-body .attachments-sections ul li {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }
  
   .message-body .attachments-sections ul li .thumb {
    display: inline-block;
    margin-right: 10px;
  }
  
   .message-body .attachments-sections ul li .thumb i {
    font-size: 30px;
    margin: 0;
    color: #2e383e;
  }
  
   .message-body .attachments-sections ul li .details p.file-name {
    display: block;
    margin-bottom: 0;
    color: #2e383e;
  }
  
   .message-body .attachments-sections ul li .details .buttons .file-size {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 13px;
  }
  
   .message-body .attachments-sections ul li .details .buttons a {
    font-size: 13px;
    margin-right: 10px;
  }
  
   .message-body .attachments-sections ul li .details .buttons a:last-child {
    margin-right: 0;
  }
  .mail-icons a{
    padding-left: 30px;
    font-size: 16px;
    color: #6B6E6F;
  }
  .mail-icons a:hover{
    color: #5d6061;
  }
  .mail-title h3{
    font-style: bold;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .profile-tag h3{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1C1F37;
  }
  .profile-tag p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #1C1F37;
  }

  .submenu{
    height: 200px;
    overflow-y: scroll;
  }
  .over-flo{
    overflow-y: scroll;
  }
  .stages-tag{
    border: 0.5px dashed #6B6E6F;
    box-sizing: border-box;
    border-radius: 9px;
  }
.active-stage{
    height: 10px;
    width: 10px;
    background-color:#017EFA;;
    border-radius: 50%;
    display: inline-block;
}

.template-placeholder:hover{
  text-decoration: none;
  color: #6B6E6F!important;
}

.img-container{
  width:100%;
   height:500px; 
   border:1px solid #000;
}
.img-container img{
  width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.file-container{
   width:95%;
   margin: 0 auto;
   height:122px; 
   border:1px solid #000;
}
.file-container img{

  width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
/* email template */
.email_template{
  display: flex;
  width: 65vw;
  
}
.temp-1{
  width: 35%;
}
.temp-2{
  width: 75%;
  /* background-color: #1C1F37; */
}
.temp_btn{
  color: #6B6E6F;
  padding: 5px 5px;
  /* background-color:#E2F1FF */
}
.temp_btn:hover{
  background-color:#E2F1FF
}
.temp_btn .active-temp{
  color: #000000;
  background-color:#E2F1FF;

}

/* Interviews Emails */
.interview-email-subject, .interview-placeholders{
  display: flex;
  border-bottom: 1px solid #dee2e6!important;
  align-items: center;
  padding: 5px 0px;
}
.interview-placeholders{

  border-top: 1px solid #dee2e6;
}

.interview-email-subject h4, .interview-placeholders h4{
  width: 15%;
  text-align: right;
  margin-right: 10px;
  
}
.interview-email-subject h5{
  font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
}
.interview-placeholders ul{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
}
.interview-placeholders ul li {
  margin-right: 10px;
  background: #E3E8F1;
  border-radius: 5px;
  padding: 5px 10px;
}
.interview-placeholders ul li a{

  color: black !important;
}

.interview-email-subject span, .interview-placeholders span {
  font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;

color: #6B6E6F;

}
/* Pipeline */
.pipeline-width{
  display: -webkit-inline-box;
  overflow: auto;
  width: 100%;
}
.pipeline-width .boards{
  margin: 10px;
  width: 291px;
  overflow-y: scroll;
  height: 100vh;
}
.kanban-item{
  background: #FFFFFF;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
  border-radius: 6px;
  margin: 21px 2px;
}
.kanban-item-head{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DADADA;
  padding: 15px;
}
.kanban-img{
  width: 60px;
  height: 60px;
}
.kanban-name h4{
  font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
}
.kanban-meta{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.kanban-icon a{
  color: #000;
}
.kanban-date{
  font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 15px;
}

/* Profile Settings */
.profile-tab{
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
.profile-tab h3{
  font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;

color: #000000;
}
.more-btn svg{
  font-size: 30px;
  cursor: pointer;
}
.addrecruiter{
  float: right;
}
.profile-tab-info{
  padding-left: 20px;
}
.profile-tab-info h4 {

font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;

color: #6B6E6F;
}
.profile-tab-info span{
  padding-right: 10px;
  padding-left: 10px;
}

/* Pipeline settings  */
.active-job-card{
  display: flex;
  justify-content: space-between;
  
background: #F5F5F5;
box-shadow: 0px 5px 10px #F1F2FA;
border-radius: 8px;
padding: 20px;
width: 631px;
margin: 20px;
}
.active-job-title{
  display: flex;
  justify-content: space-between;
}
.active-job-card a{
  color: black;
  font-size: 20px;
}
.pipe-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F7FB;
  padding: 15px;
  margin-bottom: 20px;
}
.pipe-item svg {
  font-size: 25px;
}
.pipe-title{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.modal-22{
  max-width: 659px;
}
.pipe-add{
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.pipe-add-title{
  text-align: right;
  padding-bottom: 20px;
}
.pipe-add-btn{
  padding-top: 20px;
}
.pipe-add-input input{
  background: #F5F7FB;
  padding: 15px;
  border: none;
  display: block;
  width: 100%;
}
.pipe-add-input select{
  background: #F5F7FB;
  padding: 15px;
  border: none;
  display: block;
  width: 100%;
}

.pipe-add-input-selecte{
  background: #F5F7FB;
  padding: 15px;
  border: none;
  display: block;
  width: 100%;
}
.pipe-add-input textarea{
  background: #F5F7FB;
  padding: 15px;
  border: none;
  display: block;
  width: 100%;
}

.active_plan{

  width: 700px;

}
/* Email reply */
.fullwidth{
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.email-container{
  width: 50%;
  margin: 0 auto;
  background-color: rgb(219, 219, 219);
  border-radius: 20px;
  padding: 3%;
}
.replyedit{
  /* height: 30vh; */
}
/* Media queries   */

@media only screen and (max-width: 1200px) {
    .sidebar{
        width: 70px;
    }
    
     .sidebar .sidebar-brand,
     .sidebar li {
        padding-left: 1rem;
        text-align: center;
    }
    
     .sidebar .sidebar-brand h2 span:last-child,
     .sidebar li a span:last-child{
        display: none;
    }
    
     .sidebar .sidebar-help .dark-card{
        display: none;
    }
     .main-content{
        margin-left: 70px;
    }
    
   .main-content header{
        width: calc(100% - 70px);
        left: 70px;
    }

    .sidebar:hover{
        width: 260px;
        z-index: 200;
    }
    
     .sidebar:hover .sidebar-brand,
     .sidebar:hover li {
        padding-left: 2rem;
        text-align: left;
    }
    
    .sidebar:hover li a{
        padding-left: 1rem;
    }

     .sidebar:hover .sidebar-brand h2 span:last-child,
     .sidebar:hover li a span:last-child{
        display: inline;
    }
}

@media only screen and (max-width: 960px) {
    .cards{
        grid-template-columns: repeat(3, 1fr);
    }

    .recent-grid {
        grid-template-columns: 60% 40%;
    }
} 

@media only screen and (max-width: 768px) {
    .cards{
        grid-template-columns: repeat(2, 1fr);
    }

    .recent-grid {
        grid-template-columns: 100%;
    }

    .search-wrapper, .trial-btn, .jobboard-btn {
        display: none;
    }

    #nav-toggle:checked + .sidebar{
        left: 0 !important;
        z-index: 100;
        width: 260px;
    }
    
    #nav-toggle:checked + .sidebar .sidebar-brand,
    #nav-toggle:checked + .sidebar li {
        padding-left: 2rem;
        text-align: left;
    }
    
    #nav-toggle:checked + .sidebar li a{
        padding-left: 1rem;
    }

    #nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
    #nav-toggle:checked + .sidebar li a span:last-child{
        display: inline;
    }

    #nav-toggle:checked ~ .main-content {
        margin-left: 0rem !important;
    }
} 
@media (max-width: 991px){
  .dropdown.show .dropdown-menu {
    display: block!important;
    position: static !important;
    float: none!important;
    background: transparent!important;
  }
  .navbar .navbar-nav .dropdown-menu .dropdown-menu{
    max-height: unset !important;
  }
  .dropdown .dropdown-menu .dropdown-toggle.dropdown-item {
    pointer-events: none;
  }
}
@media (max-width: 1440px){
  .mail-view{
    width: 800px;
  }
  .msg-box-width{
    width: 600px;
  }
}

@media only screen and (max-width: 560px) {
    .cards{
        grid-template-columns: 100%;
    }
    .sidebar{
        left: -100% !important;
    }

    header h2 .nav-label {
        display: inline-block;
        text-align: center;
        padding-left: 0;
        margin-right: 1rem;
        height: 40px;
        width: 40px;
    }

    header h2 {
        font-size: 1.1rem;
    }

    .main-content{
        width: 100%;
        margin-left: 0px !important;
    }

    header{
        width: 100% !important;
        left: 0 !important;
    }
    .add-container{
      width: 100%;
    }
    .add-flex{
      display: block;
      justify-content: center;
      align-items: center;
    }
} 

/* New Mailbox edits */

.new-mailbox {
  display: flex;
  width: 100%;
  /* height: 50px; */
  /* background-color: green; */
}

.new-mailbox-sidemenu {
  width: 20%;
  /* background-color: red; */
}
.new-mailbox-items{
  display: flex;
  width: 100%;
}


.new-mailbox-list {
  width: 30%;
  /* background-color: blue; */
}

.new-mailbox-opened {
  width: 70%;
  /* background-color: yellow; */
}

.message-body-top{
  /* max-width: 60%;*/
  width: 100%; 
  display: flex;
  justify-content: space-between;
}
.message-body-left{
  /* width: 70%;
  max-width: 70%; */
  display: flex;
  width: 70%;
}
.message-body-right{
  width: 30%;
  max-width: 30%;
  text-align: end;
}
.sender_id{
  /* width: 100%; */
  display: inline-flex !important;
  align-items: center;
}
.recipients_name{
  /* width: 60%; */
  width: 500px;
  overflow-wrap: break-word;

}
.mailer-icon{
  border-radius: 100%;
  height: 55px;
}
.email-text{
  padding: 20px;
}
.just-text{
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.editor-class{
  /* height: 60% !important; */
  height: 260px !important;
  max-height: 300px !important;
  background-color: whitesmoke;
}